import request from '@/utils/request'

export function cateList(query) {
  return request({
    url: '/admin/article/cate_list',
    method: 'get',
    params: query
  })
}
// 文章分类下拉
export function cateDropDownList(query) {
  return request({
    url: '/admin/article/getDropDownForEdit',
    method: 'get',
    params: query
  })
}
// 文章分类详情
export function cateDetail(query) {
  return request({
    url: '/admin/article/detailCate',
    method: 'get',
    params: query
  })
}

// 文章分类删除
export function delCate(query) {
  return request({
    url: '/admin/article/delCate',
    method: 'get',
    params: query
  })
}

// 文章禁用启用
export function saveStatusCate(query) {
  return request({
    url: '/admin/article/saveStatusCate',
    method: 'get',
    params: query
  })
}



//添加 编辑文章分类
export function addArticleCate(type,query) {
  let url = ''
  if (type == 'add'){
    url = '/admin/article/addCate'
  }else {
    url ='/admin/article/saveCate'
  }
  return request({
    url: url,
    method: 'post',
    data: query
  })
}

// 文章列表  /admin/article/article_list
export function articleList(params) {
  return request({
    url: '/admin/article/article_list',
    method: 'get',
    params: params
  })
}


// 文章列表  /admin/article/saveStatusArticle
export function saveStatusArticle(params) {
  return request({
    url: '/admin/article/saveStatusArticle',
    method: 'get',
    params: params
  })
}

//删除文章 /admin/article/delArticle
export function delArticle(params) {
  return request({
    url: '/admin/article/delArticle',
    method: 'get',
    params: params
  })
}

//文章详情 admin/article/detailArticle
export function detailArticle(params) {
  return request({
    url: 'admin/article/detailArticle',
    method: 'get',
    params: params
  })
}

//新增文章 admin/article/addArticle
export function addArticle(data) {
  return request({
    url: '/admin/article/addArticle',
    method: 'post',
    data
  })
}

//编辑文章
export function editArticle(data) {
  return request({
    url: '/admin/article/saveArticle',
    method: 'post',
    data
  })
}



export function fetchPv(pv) {
  return request({
    url: '/vue-element-admin/article/pv',
    method: 'get',
    params: { pv }
  })
}

export function createArticle(data) {
  return request({
    url: '/vue-element-admin/article/create',
    method: 'post',
    data
  })
}

export function updateArticle(data) {
  return request({
    url: '/vue-element-admin/article/update',
    method: 'post',
    data
  })
}
